.container {
    width: 82%;
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 40px;
  }
  .title-main h3 {
    padding: 20px 0px 20px 0px;
  }

  .portada_ciudad {
    background-image: url("../assets/img/unt_img2.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    padding: 0px 0px 0px 30px;
    margin-bottom: 30px;
  }
  .contents {
    padding: 10px 0px;
  }
  .container h4,
  p {
    color: #5a5a5a;
  }
  .container h3 {
    color: #444;
  }

  .container img {
      width: 100% !important;
  }