.container_subunidades{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 30px; 
}

.container_subunidades .portada{
    width: 100%;
    height: 350px;
    margin-left: 0px;
}

.container_subunidades .portada .img-portada {
    width: 100%;
    height: 350px;
    background-image: url("../assets/img/unt_img1.png");
    background-size: cover;
    border-radius: 0px 90px 0px 0px;
}
.container_subunidades .portada .img-portada2 {
    width: 100%;
    height: 350px;
    background-image: url("../assets/img/unt_img2.png");
    background-size: cover;
    border-radius: 90px 0px 0px 0px;
}

.container_subunidades .portada .nombre-portada{
    width: 100%;
    height: auto;
    display: flex;
}

.container_subunidades .portada .nombre-portada h2{
    color: #000;
    font-size: 25px;
}

.container_subunidades .portada .nombre-portada h2 label{
    color: #E6AD09;
    font-size: 35px;
    font-weight: 900;
}

.container_subunidades .portada .contenedor-descripcion{
    padding: 10px;
    margin: auto;
    margin-left: -100px;
    background-color: #FFF;
    -webkit-box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
    -moz-box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
    box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
    border-radius: 7px;
    padding: 20px;
}

.container_subunidades .portada .contenedor-descripcion2{
    padding: 10px;
    margin: auto;
    margin-right: -100px;
    background-color: #FFF;
    -webkit-box-shadow: -1px 5px 24px 4px rgba(235,235,235,1);
    -moz-box-shadow: -1px 5px 24px 4px rgba(235,235,235,1);
    box-shadow: -1px 5px 24px 4px rgba(235,235,235,1);
    border-radius: 7px;
    padding: 20px;
    z-index: 100;
}

.container_subunidades .portada .contenedor-descripcion p{
    text-align: justify;
    margin-bottom: 0px;
    color:rgb(104, 104, 104);
}

.container_subunidades .portada .contenedor-descripcion2 p{
    text-align: justify;
    margin-bottom: 0px;
    color:rgb(104, 104, 104);
}

.container_subunidades .container_functions{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}


.container_subunidades .container_functions ul li{
    display: inherit;
}
.container_subunidades .container_functions ul li i{
    margin-top: 5px;
    color: #E6AD09;
}
.container_subunidades .container_functions ul li p{
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 15px;
    color:rgb(77, 77, 77);
}
.container_subunidades .container_functions p{
    text-align:justify;
}

/*separador*/

.container_subunidades .separador{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.container_subunidades .separador .contenedor{
    margin: auto;
}

.container_subunidades .separador .left{
    margin-left: auto;
}

.container_subunidades .separador .right{
    margin-right: auto;
}

.container_subunidades .separador .contenedor i{
    color: rgb(207, 207, 207);
    font-size: 3px;
    margin: 0px 5px;
}

.container_subunidades .separador .contenedor p{
    margin-bottom: 0px;
    line-height: 10px;
}

@media screen and (max-width: 992px) {
    .container_subunidades .portada .nombre-portada{
        display: block;
    }

    .container_subunidades .portada .contenedor-descripcion{
        margin-left: 0px;
        height: auto;
        margin-top: 20px;
    }
    
    .container_subunidades .portada .contenedor-descripcion2{
        margin-right: 0px;
        height: auto;
        margin-bottom: 20px;
    }

    .container_subunidades .portada{
        width: 100%;
        height: auto;
    }
}