body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* color organigrama */
[data-n-id='1'] rect {
  fill: #FFB1A0;
}
[data-n-id='2'] rect {
  fill: #FFE181;
}
[data-n-id='5'] rect {
  fill: #FFE181;
}
[data-n-id='4'] rect {
  fill: #B8FCB1;
}
[data-n-id='3'] rect {
  fill: #B1DBFC;
}
[data-n-id='6'] rect {
  fill: #B1DBFC;
}
[data-n-id='7'] rect {
  fill: #B1DBFC;
}
[data-n-id='8'] rect {
  fill: #B1DBFC;
}
[data-n-id='9'] rect {
  fill: #B8FCB1;
}


/* estilos globales de cards*/
.card{
  -webkit-box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
  -moz-box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
  box-shadow: 9px 5px 24px 4px rgba(235,235,235,1);
  border: 0px !important;
  border-radius: 10px !important;
  margin: 10px;
}
.card .card-img-top {
  border-radius: 10px 10px 0px 0px !important;
}

.card .card-title {
  font-weight: bold;
  font-size: 14px;
  color: #1b4079;
}

.card .card-title {
  font-weight: bold;
  font-size: 14px;
}

.card .card-text{
  font-size: 14px;
}

.card .card-img-left {
  border-radius: 10px 0px 0px 10px !important;
  width: 100%;
  height: 100%;
}

/* fin estilos cards*/