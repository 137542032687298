.card{
    text-decoration: none !important;
    height: 100%;
}

.card .card-img-top{
    height: 250px !important;
}

.card .card-text {
    color: #888888 !important;
}


.horizontal-card .badge{
    font-size: 14px;
    position: absolute;
    z-index: 100;
    bottom: 0px;
    margin-bottom: 7px;
    margin-left: 7px;
}
.card-body button{
    border-color: #000C4B;
    float: right;
    color: #000C4B;
}
.card-body button:hover {
    border-color: #E6AD09;
    background-color: #E6AD09;
    color:#fff;
}