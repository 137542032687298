.container-home{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 30px; 
}

.home_herramientas {
    width: 100%;
    text-align:center;
    margin: auto;
   
}
.home_herramientas img {
    width: 100px;
}
.h_herramienta{
    padding-top:20px;
    padding-bottom: 20px;
    border-radius: 10px !important;
    background-color: #fff;
    transition: background-color .5s;
}
.h_herramienta a{
    text-decoration:none;
    color:#1B1811;
}

.h_herramienta a h5{
    margin-top: 15px;
}
/*.h_herramienta:hover{
    background-color: #e9ecef;
}*/



   .h_herramienta:hover {
    background-color: #E6AD09;
   }
 
  
  



.home_noticias {
    margin-top: 60px;
}
.home_noticias h3{
    margin-bottom: 60px;
}
.home_collage{
    margin-top: 40px;
}
.home_collage h3{
    margin-bottom: 60px;
}