.contenedor_header  {
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: #E6AD09;
}
.carousel{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 200;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.carousel-inner
{
    height: 100% !important;
}


.carousel-item{
    height: 100%;
    width: 100%;
}

.img1 img{
 
    width:100%;
}