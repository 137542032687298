.container_nosotros{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 30px; 
}
.nosotros_portada img{
    width: 100%;
    
}

.mvv{
    margin-top: 60px;
    text-align: center;
    
}
.mvv img {
    width:150px;
}
.mvv p {
    text-align: justify;
}
.jefe_uda{
    margin-top: 60px;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    background-color: #e9ecef;
}
.img_jefe {
    text-align:center;
    margin-top:20px;
    margin-bottom:20px;
}
.mensaje_jefe{
    margin: auto;
}
.mensaje_jefe {
    padding: 20px 30px;
}

.mensaje_jefe p{
    text-align: justify;
    font-style: italic;
    
    
}
.mensaje_jefe h6 {
    color: #000C4B;
    margin-bottom: 0px;
}

.container_orgchart{
    width: 100%;
    margin-top:30px;
}

.container_orgchart h3 {
    margin-top: 60px;
}

.container_functions{
    width: 100%;
    margin-top: 60px;
}

.container_functions ul li{
    display: inherit;
}
.container_functions h3{
    display: inline-block;
    margin-bottom: 0px;
}

.container_functions ul li i{
    margin-top: 5px;
    color: #E6AD09;
}
.container_functions ul li p{
    margin-bottom: 0px;
    margin-left: 10px;
}
.container_functions p{
    text-align:justify;
}
.card-img-top {
    margin-right: 0px;
}