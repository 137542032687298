.container_docs{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 30px; 
}
.docs{
    width: 80%;
    margin-left:5%;
    margin-right: 5%;
    margin-bottom:10px;
    padding: 10px;
    
}
.docs a{
    text-decoration: none;
    color: #1B1811;
}
.docs i{
    color:#27241f ;
}
.docs :hover {
    color: #E6AD09;
}