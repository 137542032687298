.contenedor_navbar {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0px;
  }
  .barra_top{
      width: 100%;
      background-color: #000C4B;
      color: #fff;
      display: flex;
      text-align:center;
  }

  .barra_top .telefono ,.correo_institucional {
      width: 50%;
      margin-top: 5px;
  }
  
  .logo_nav {
    width: 200px;
  }

  .nav-link {
    color: #000C4B   !important;
    transition: color 0.3s;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.3s;
  }
  
  .nav-link:hover {
    color: #E6AD09 !important;
    border-bottom: 1px solid #E6AD09;
  }
  
  .nav-item {
    padding-left: 8px;
    padding-right: 8px;
  }
  #btn_search:hover {
    background-color: #E6AD09 !important;
    color: #fff !important;
  }
  .navbar-light .navbar-toggler {
    color: #1b4079;
    border-color: #fff;
  }
  
  .logo_uda {
    width: 20%;
  }
  
  .navbar {
    width: 60%;
    background-color: #ffffff;
    padding: 15px 60px 15px 60px;
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-box-shadow: 2px 2px 19px -8px rgba(82,79,82,1);
    -moz-box-shadow: 2px 2px 19px -8px rgba(82,79,82,1);
    box-shadow: 2px 2px 19px -8px rgba(82,79,82,1);
    border: 0px !important;
  }
  .navbar-nav {
    margin-right: 10%;
    margin-left: 10%;
  }
  .collapse {
    width: 60%;
    text-align: left;
  }
  .navbar-nav li {
    margin: px;
  }
  
  .space-bar {
    height: 121.91px;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    .logo_nav {
      width: 100px !important;
    }
    .space-bar {
      height: 101.91px;
    }
  }
  
  @media screen and (max-width: 1100px) {
    #buscador_nav {
      width: 150px !important;
    }
  
    .nav-item {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .logo_nav {
      width: 100px;
    }
    .collapse {
      margin-left: 0px;
      background: #fff;
    }
    .nav-link {
      text-align: center;
      color: #1b4079 !important;
    }
  
    .contenedor_buscar_nav {
      margin: auto;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 575px) {
    .logo_uda img {
       margin-left:-40px;;
    }
    .rrss_bar ul {
      float: none;
      padding: 0px;
      text-align: center;
    }
    .navbar-nav {
      margin-top: 20px;
    }
  
    .nav-link {
      font-size: 15px;
    }
  
    .navbar-brand {
      padding-left: 0px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .rrss_bar ul {
      float: none;
      padding: 0px;
      text-align: center;
    }
  }
  