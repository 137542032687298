.container_contacto{
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 30px; 
}
.contacto_portada {
    width: 100%;
}
.contacto_portada img {
    width: 100%;
}
.contacto_icon img{
    width:40px;
}
.container_contacto p{
    margin-bottom: 0px;
}
.container_contacto h4 {
    margin: 45px auto;
    text-align: center;
    color: #1B1811 ;
}
.contacto_horario, .contacto_direccion, .contacto_telefono, .contacto_correo {
    background-color:#e9ecef;
    margin:5px 5px;
    padding:15px;

}
.contacto_mapa {
    width: 100%;
    margin-top: 30px;
}

.contacto_icon{
    text-align: center;

}