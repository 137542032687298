
.container_footer{
    background-color: #fff;
    background-color: #E6AD09;
    
}
.container_footer_top{
    width: 100%;
    background-color: #000C4B;
    height: 100%;

    margin-left: 0px;
    margin-right: 0px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 30px;
    margin-top: 60px;
    display:flex;
    text-align:left;
    color: #ffff;
    clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 100%);
    
}
.container_footer_top span{
    color: #8395b2;
    padding-bottom: 20px;
    padding-bottom: 30px;
}
.container_footer_top .footer_top_1 a{
    display: block;
    border-top: 1px solid #485569;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffff;
    text-decoration: none;    
}
.container_footer_top .footer_top_1 i{
    float:right;
    padding: 0px 10px;
}
.container_footer_top .footer_top_2{
    padding: 0px 10px;
}
.container_footer_top .footer_top_3{
    padding: 0px 10px;
}
.container_footer_top .footer_imagenes {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    
}
.container_footer_top .footer_img_1 {
    width: 45%;
    border-radius: 10%;
    background-color: #12377B;
    margin-right: 10%;
    margin-top: 30px;
    margin-bottom:30px ;
   

}
.container_footer_top .footer_img_2 {
    width: 45%;
    background-color: #8395b2;
    border-radius: 10%;
    margin-top: 30px;
    margin-bottom:30px ;
    
}
.container_footer_top .footer_img_1:hover{
    background-color: #1B1811 ;
}
.container_footer_top .footer_img_2:hover {
    background-color: #fff;
}
.container_footer_top .footer_top_3 .footer_rrss {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}
.container_footer_top .footer_top_3 .footer_rrss .enlace_rrss{
    background-color: #12377B;
    width: 40px;
    height: 40px;
    text-align:center;
    border-radius: 100%;
    margin: auto;
    padding:8px;
}

.container_footer_bottom{
    width: 100%;
    background-color: #000C4B;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px;
    border-top: 1px solid #8395b2;
  }
  
  .container_footer_bottom p {
    width: 100%;
    color: #ffff;
    text-align: center;
    margin:0px;
  }
  